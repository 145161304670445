import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 313.000000 367.000000">
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,367.000000) scale(0.100000,-0.100000)">

<path d="M1414 3306 c-236 -45 -409 -137 -574 -305 -549 -560 -309 -1502 440
-1731 427 -130 895 24 1155 382 61 83 139 245 165 338 142 518 -132 1065 -630
1258 -163 63 -399 88 -556 58z m293 -582 c65 -38 97 -92 101 -174 5 -79 -12
-123 -64 -173 -40 -37 -42 -45 -11 -56 12 -5 39 -23 61 -40 21 -17 40 -31 42
-31 9 0 74 43 74 49 0 5 -10 16 -21 27 -54 48 -74 158 -43 232 35 85 130 141
219 128 87 -14 164 -89 180 -178 10 -55 -1 -100 -41 -159 -32 -49 -34 -53 -17
-62 39 -21 136 -131 159 -181 26 -58 49 -159 40 -183 -4 -10 -18 -13 -48 -11
l-43 3 -7 60 c-19 150 -121 255 -248 255 -58 0 -116 -22 -133 -51 -8 -12 -5
-29 10 -60 46 -98 57 -212 27 -275 -30 -62 -123 -83 -185 -41 -82 55 -82 231
1 347 11 15 20 34 20 42 0 15 -60 59 -108 78 -67 26 -207 -5 -254 -57 -16 -17
-15 -21 14 -68 34 -55 45 -84 58 -165 11 -59 -6 -125 -41 -163 -27 -29 -87
-46 -123 -35 -43 14 -93 72 -101 117 -8 49 17 165 49 228 l25 50 -48 27 c-70
38 -150 37 -213 -3 -77 -49 -127 -133 -143 -244 l-7 -48 -42 3 -41 3 3 45 c7
126 90 266 185 316 15 8 27 17 27 19 0 3 -16 25 -35 50 -80 106 -50 250 65
315 57 31 137 32 195 3 50 -26 68 -46 94 -103 35 -76 25 -155 -29 -221 -16
-21 -30 -40 -30 -42 0 -5 68 -47 75 -47 2 0 22 16 45 35 23 19 48 35 55 35 29
0 25 23 -9 57 -50 50 -70 108 -63 184 14 161 183 246 324 163z"/>
<path d="M1560 2683 c-14 -3 -39 -15 -56 -28 -75 -57 -82 -159 -15 -226 48
-49 102 -58 165 -30 58 25 86 69 86 136 0 65 -26 106 -82 134 -44 21 -52 22
-98 14z"/>
<path d="M1100 2582 c-38 -20 -70 -72 -70 -112 0 -37 35 -88 74 -106 46 -23
101 -11 138 28 36 39 44 70 29 118 -21 72 -106 107 -171 72z"/>
<path d="M1985 2584 c-44 -22 -66 -57 -67 -107 -1 -68 51 -127 112 -127 65 0
130 63 130 125 0 43 -35 94 -75 111 -43 18 -62 17 -100 -2z"/>
<path d="M1325 2068 c-10 -24 -20 -73 -23 -111 -4 -56 -2 -71 13 -87 37 -41
95 -12 95 46 -1 62 -42 194 -60 194 -5 0 -16 -19 -25 -42z"/>
<path d="M1827 2089 c-34 -54 -57 -199 -37 -225 15 -17 71 -19 88 -2 17 17 15
102 -4 173 -19 74 -28 84 -47 54z"/>
<path d="M2750 976 c0 -119 4 -187 11 -191 5 -4 21 -4 35 -1 25 7 25 8 23 102
-3 98 3 114 41 114 37 0 45 -18 50 -117 5 -97 5 -98 30 -98 25 0 25 0 28 108
3 103 2 108 -22 132 -27 27 -64 32 -98 14 -11 -6 -21 -9 -23 -7 -3 3 -8 42
-14 121 -1 4 -14 7 -31 7 l-30 0 0 -184z"/>
<path d="M365 1136 c-85 -32 -127 -125 -101 -224 26 -101 75 -137 180 -130
112 7 162 70 154 197 -4 78 -22 113 -75 143 -42 25 -110 31 -158 14z m121 -64
c33 -26 44 -55 44 -112 0 -117 -92 -170 -166 -96 -31 31 -34 40 -34 90 0 60
18 108 47 125 28 16 86 12 109 -7z"/>
<path d="M957 1134 c-12 -12 -8 -342 4 -349 5 -4 21 -4 34 -1 24 6 24 9 27
119 l3 112 70 -115 c64 -105 73 -115 100 -115 l30 0 3 178 2 177 -30 0 -30 0
0 -109 c0 -104 -11 -147 -27 -104 -4 10 -9 20 -12 23 -4 3 -15 21 -25 41 -45
86 -91 144 -116 147 -14 2 -29 0 -33 -4z"/>
<path d="M1580 1085 c0 -14 -8 -29 -20 -35 -11 -6 -20 -19 -20 -29 0 -10 9
-21 20 -24 18 -5 20 -14 20 -95 0 -83 2 -92 22 -106 12 -9 37 -16 55 -16 41 0
44 23 8 56 -24 22 -26 30 -23 91 3 62 5 68 26 71 31 5 29 40 -3 52 -18 7 -25
17 -25 35 0 21 -5 25 -30 25 -25 0 -30 -4 -30 -25z"/>
<path d="M2590 1081 c0 -20 -6 -30 -20 -34 -11 -3 -20 -14 -20 -25 0 -11 9
-22 20 -25 18 -5 20 -14 20 -91 0 -73 3 -89 21 -107 28 -28 83 -23 87 9 2 15
-2 22 -13 22 -19 0 -35 39 -35 88 0 56 10 82 31 82 26 0 26 40 0 47 -11 3 -21
17 -25 34 -5 24 -10 29 -36 29 -26 0 -30 -3 -30 -29z"/>
<path d="M664 1037 c-2 -7 -3 -67 -2 -133 l3 -119 30 0 30 0 3 98 c3 93 4 98
28 109 43 20 58 -7 64 -115 l5 -92 30 0 c30 0 30 1 33 60 5 93 -6 163 -31 185
-25 24 -64 26 -102 6 -24 -13 -30 -13 -42 0 -18 18 -42 18 -49 1z"/>
<path d="M1332 1022 c-37 -34 -46 -66 -40 -133 8 -82 69 -123 160 -105 45 9
60 20 52 39 -5 14 -15 16 -55 11 -40 -5 -53 -3 -69 11 -43 39 -21 55 77 55
l66 0 -7 43 c-12 76 -48 107 -121 107 -22 0 -42 -9 -63 -28z m103 -38 c24 -25
13 -39 -33 -42 -46 -3 -56 13 -26 42 20 20 38 20 59 0z"/>
<path d="M1726 1042 c-7 -12 50 -242 63 -255 5 -5 20 -7 33 -4 18 5 27 19 42
72 10 36 23 64 29 62 5 -1 18 -31 29 -65 19 -64 39 -83 66 -66 16 11 73 206
69 238 -3 25 -45 32 -54 9 -4 -9 -11 -41 -17 -70 -6 -30 -16 -53 -21 -52 -6 1
-18 30 -29 65 -30 100 -62 96 -91 -11 -10 -36 -21 -65 -26 -65 -4 0 -13 26
-19 58 -6 31 -14 64 -19 72 -9 18 -46 25 -55 12z"/>
<path d="M2154 1031 c-41 -25 -58 -68 -52 -137 6 -71 36 -104 99 -111 58 -6
94 9 119 49 24 40 27 116 5 158 -30 57 -112 77 -171 41z m100 -50 c18 -19 24
-81 12 -119 -16 -48 -79 -39 -96 14 -27 81 36 159 84 105z"/>
<path d="M2394 1035 c-3 -6 -3 -65 -2 -131 l3 -119 30 0 30 0 5 95 5 95 32 9
c29 9 50 45 35 61 -11 10 -40 5 -52 -10 -10 -12 -15 -13 -21 -3 -10 16 -59 19
-65 3z"/>
<path d="M1540 565 c0 -65 2 -116 4 -114 8 7 9 219 2 226 -3 4 -6 -47 -6 -112z"/>
<path d="M732 558 c3 -128 5 -130 87 -108 47 12 71 48 71 104 0 77 -42 116
-125 116 l-36 0 3 -112z m115 70 c31 -29 33 -118 4 -147 -21 -21 -83 -37 -100
-26 -7 4 -11 45 -11 101 l0 94 42 0 c29 0 49 -7 65 -22z"/>
<path d="M1690 660 c8 -5 25 -10 38 -10 21 0 22 -3 22 -100 0 -64 4 -100 11
-100 6 0 9 34 8 98 l-2 97 29 3 c16 2 33 8 39 13 5 5 -24 9 -75 9 -59 0 -80
-3 -70 -10z"/>
<path d="M1270 632 c0 -12 -5 -22 -12 -22 -9 0 -9 -3 0 -12 7 -7 12 -39 12
-73 0 -62 11 -85 40 -85 12 1 12 3 -3 14 -15 12 -18 27 -16 95 2 52 -1 85 -9
93 -9 9 -12 7 -12 -10z"/>
<path d="M2323 623 c-8 -17 -9 -30 -3 -38 5 -6 10 -40 12 -75 3 -56 6 -65 23
-69 19 -3 19 -3 3 16 -13 14 -18 36 -18 76 0 43 4 58 18 66 10 6 12 11 4 11
-7 0 -16 9 -20 19 -6 18 -8 18 -19 -6z"/>
<path d="M1024 589 c-13 -15 -19 -35 -18 -65 1 -52 25 -84 63 -84 26 1 26 1
-5 14 -17 8 -34 21 -38 31 -19 49 5 110 47 118 20 4 19 4 -4 6 -15 0 -34 -8
-45 -20z"/>
<path d="M1388 599 c-10 -5 -18 -15 -18 -21 0 -6 8 -4 18 5 21 19 40 21 61 8
17 -11 24 -87 10 -112 -7 -14 5 -29 24 -29 1 0 1 30 -1 67 -2 48 -8 72 -20 81
-19 14 -50 15 -74 1z"/>
<path d="M1905 600 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M2165 594 c-23 -23 -15 -47 20 -62 53 -23 60 -29 60 -47 0 -31 -31
-42 -65 -23 -17 9 -30 13 -30 8 0 -13 35 -30 63 -30 24 0 57 27 57 47 0 14
-31 39 -60 48 -40 12 -51 24 -39 46 12 23 46 25 71 2 23 -21 35 -11 13 11 -21
21 -69 21 -90 0z"/>
<path d="M940 524 c0 -41 4 -73 8 -70 12 7 13 146 1 146 -5 0 -9 -34 -9 -76z"/>
<path d="M1117 513 c1 -48 -3 -95 -8 -104 -5 -11 -24 -19 -47 -22 l-37 -4 42
-1 c61 -3 73 19 73 130 0 64 -3 88 -13 88 -9 0 -12 -22 -10 -87z"/>
<path d="M1190 525 c0 -43 4 -75 10 -75 6 0 10 32 10 75 0 43 -4 75 -10 75 -6
0 -10 -32 -10 -75z"/>
<path d="M1871 523 c1 -90 5 -87 12 10 3 37 1 67 -4 67 -5 0 -9 -35 -8 -77z"/>
<path d="M1979 596 c-9 -9 0 -115 11 -137 7 -12 21 -19 38 -19 l27 1 -32 14
c-31 13 -32 16 -35 80 -2 37 -6 64 -9 61z"/>
<path d="M2086 592 c-9 -15 -5 -142 5 -142 5 0 9 34 9 75 0 72 -3 85 -14 67z"/>
<path d="M1373 523 c-16 -6 -17 -55 -1 -71 7 -7 23 -12 38 -12 24 1 23 2 -7
15 -38 16 -42 38 -11 59 22 15 9 21 -19 9z"/>
</g>
</svg>

		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
